<template>
  <div class="py-4">
    <div class="shadow border border-gray-200 rounded-md mb-6">
      <div class="py-4 px-6 text-xl font-semibold">
        Invoices
      </div>
    </div>

    <div class="shadow border border-gray-200 rounded-md">
      <div
        v-if="!showInvoices"
        class="flex justify-center text-xl font-semibold p-6 gap-2"
      >
        <div v-if="isLoading" class="spinner dark"></div>
        <div>{{ statusMessage }}</div>
      </div>
      <table v-if="showInvoices" class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Invoice Number
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Date
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Amount
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Status
            </th>
            <th scope="col" class="relative px-6 py-3">
              <span class="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <!-- Odd row white, Even row gray-->
          <tr
            v-for="(invoice, index) in invoices"
            :key="invoice.id"
            :class="index % 2 ? 'bg-white' : 'bg-gray-50'"
          >
            <td
              class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            >
              {{ invoice.number }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ invoiceDate(invoice.lines.data[0].period.start) }} to
              {{ invoiceDate(invoice.lines.data[0].period.end) }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              $ {{ (invoice.total / 100).toLocaleString() }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ invoice.status }}
            </td>
            <td
              class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
            >
              <a
                v-if="invoice.invoice_pdf"
                class="text-blue-700 hover:text-gray-900"
                :href="invoice.invoice_pdf"
                target="_blank"
                >PDF
              </a>
              <span v-if="invoice.invoice_pdf && invoice.hosted_invoice_url">
                /
              </span>
              <a
                v-if="invoice.hosted_invoice_url"
                class="text-blue-700 hover:text-gray-900"
                :href="invoice.hosted_invoice_url"
                target="_blank"
                >Link</a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
const firebase = require("../firebaseConfig");

export default {
  name: "BillingInvoicesComponent",
  mounted() {
    this.getUser();
  },
  data: () => ({
    isLoading: true,
    showInvoices: false,
    statusMessage: "Loading . . .",
    user: {},
    business: {},
    invoices: {},
  }),
  methods: {
    getUser() {
      if (firebase.auth.currentUser.uid) {
        firebase.db
          .collection("users")
          .doc(firebase.auth.currentUser.uid)
          .onSnapshot((doc) => {
            this.user = {
              uid: doc.id,
              ...doc.data(),
            };
            this.getBusinessInfo();
          });
      }
    },
    async getBusinessInfo() {
      const business = await firebase.db
        .collection("businesses")
        .doc(this.user.business_id)
        .get();
      if (business.data().business_type != "stripe") {
        this.$router.push("/dashboard");
      }
      this.business = {
        id: this.user.business_id,
        ...business.data(),
      };
      this.getInvoices();
    },
    async getInvoices() {
      await firebase.db
        .collection("businesses")
        .doc(this.business.id)
        .collection("billing")
        .doc(this.business.id)
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            const getInvoicesFromStripe = firebase.functions.httpsCallable(
              "stripeInvoices"
            );
            getInvoicesFromStripe({
              billing_id: doc.data().id,
            }).then((result) => {
              if (result.data) {
                if (result.data.code === 200) {
                  if (result.data.data.length > 0) {
                    this.invoices = result.data.data;
                    this.showInvoices = true;
                  } else {
                    this.statusMessage = "No invoices found . . .";
                  }
                } else if (result.data.code === 400) {
                  this.statusMessage = result.data.message;
                }
              } else {
                this.statusMessage = "Unknown error . . .";
              }
              this.isLoading = false;
            });
          } else {
            this.statusMessage = "Billing not configured . . .";
            this.isLoading = false;
          }
        });
    },
    invoiceDate(unix_timestamp) {
      const date = new Date(unix_timestamp * 1000);
      return date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      });
    },
  },
};
</script>

<style scoped>
.spinner {
  height: 25px;
  width: 25px;
  background-color: transparent;
  border-top: 5px solid white;
  border-right: 5px solid white;
  border-bottom: 5px solid transparent;
  border-left: 5px solid transparent;
  border-radius: 50%;
  animation: spin-around 1s infinite linear;
}
.spinner.dark {
  border-top: 5px solid #111827;
  border-right: 5px solid #111827;
}
@keyframes spin-around {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
