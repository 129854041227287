<template>
  <BillingInvoicesComponent />
</template>

<script>
import BillingInvoicesComponent from '../components/BillingInvoicesComponent.vue'

export default {
  name: 'BillingInvoices',
  components: {
    BillingInvoicesComponent,
  },
}
</script>
